import React,{useState,useEffect} from 'react';

 import imgBack from '../../images/ggg.jpg';
 import Fade from 'react-reveal/Fade';

 import darkluna from './Cards/darkluna.mp4'
 import cap from './Cards/cap.mp4'
 import eth from './Cards/eth.mp4'
 import rage from './Cards/rage.mp4'
 import sol from './Cards/solboy.mp4'
// import Video from '../../videos/video.mp4';

 import '@splidejs/splide/dist/css/themes/splide-default.min.css';
// or
import '@splidejs/splide/dist/css/themes/splide-sea-green.min.css';
// or
import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css';
import styled from 'styled-components';

import {Link as LinkR} from 'react-router-dom';
import { Button } from '../ButtonElement';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { 
    HeroContainer,
    HeroBg,
    VideoBg,
    DivCardI,
    HeroContent,
    HeroH1,
    HeroP,
    HeroSlider,
    HeroBtnWrapper,
    ArrowForward,
    DivCard,
    ArrowRight,
    

} from './HeroElements';


const HeroSection = () => {
    const [hover, setHover]= useState(false);
    const [width, setWidth] = useState(window.screen.width);
    let primaryOptions = {
        type      : 'loop',
        width     : 370,
        height    : 600,
    	autoplay  : true,
        perPage   : 1,
        perMove   : 1,
        gap       : '2rem',
        pagination: false,
    };
    const NavBtnLink = styled(LinkR)`
    border-radius: 50px;
    background: #DA0000;
    white-space: nowrap;
    padding: 10px 22px;
    color: #FFF;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none ;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #fff;
        color: #da0000 !important;
    }
`;



    const onHover = () => {
        setHover(!hover);
    }
    return (
        <HeroContainer id="home">
            <HeroBg>
                 {/* <VideoBg autoPlay loop muted src={Video} type='video/mp4'/>  */}
                 <VideoBg  src={imgBack} />
                </HeroBg>
        <HeroContent className="justify-content-center ">
            <DivCardI>
            <HeroH1>Crypto <span style={{color:"#DA0000"}}>HERO</span> Battle Cards. </HeroH1>
            <HeroP className="">
            Crypto Heroes are a collection of limited-edition battle cards HAND Created by The RKON team are in 3 series of 1st 2nd and 3rd edition cards each having 2500 cards and 60 heroes per series. All living on the Polygon blockchain.  
            </HeroP>
            <HeroP className="">
            Click Info to find out more.  
             </HeroP>
            <HeroBtnWrapper>
                <NavBtnLink 
                className="text-decoration-none"
                to="/info"
                onMouseEnter={onHover} 
                onMouseLeave={onHover}
                primary='true'
                dark='true'
                smooth={true}
                duration={500}
                spy={true}
                exact='true'
                offset={-80}
                >
                    Learn More {hover? <ArrowForward /> : <ArrowRight/>}
                </NavBtnLink>
            </HeroBtnWrapper>
            </DivCardI>
            <DivCard>
            {/* <img style={{width: "650px", height:"400px"}} src={Video}></img>
             */}
             <div>

             </div>
             <Fade right>
         
             <Splide className=""  options={ primaryOptions } style={{}}>
                <SplideSlide >
                <video autoPlay loop muted src={darkluna} type='video/mp4'  style={{height:"80%"}}   alt="Image 1"/>
                </SplideSlide>
                <SplideSlide>
                <video autoPlay loop muted src={sol} type='video/mp4'  style={{height:"80%"}}   alt="Image 1"/>
                </SplideSlide>
                <SplideSlide> 
                    <video autoPlay loop muted src={eth} type='video/mp4'  style={{height:"80%"}}   alt="Image 1"/>

                </SplideSlide>
                <SplideSlide>
                <video autoPlay loop muted src={sol} type='video/mp4'  style={{height:"80%"}}   alt="Image 1"/>
                </SplideSlide>
                <SplideSlide>
                <video autoPlay loop muted src={cap} type='video/mp4'  style={{height:"80%"}}   alt="Image 1"/>
                </SplideSlide>
                <SplideSlide>
                <video autoPlay loop muted src={rage} type='video/mp4'  style={{height:"80%"}}   alt="Image 1"/>
                </SplideSlide>
              
            </Splide> 
             </Fade>
        </DivCard>
        </HeroContent>
       
   
        </HeroContainer>

    )  
}

export default HeroSection;
