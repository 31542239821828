import React from 'react'
import {
    FaqContainer,
    FaqWrapper,
    Accord
}
from "./CollectionElement"
import Zoom from 'react-reveal/Zoom';

import "animate.css/animate.min.css";
import Marquee from "react-fast-marquee";
import c1 from './graphics/cards/1.png'
import c2 from './graphics/cards/2.png'
import c3 from './graphics/cards/3.png'
import c4 from './graphics/cards/4.png'
import c5 from './graphics/cards/5.png'
import c6 from './graphics/cards/6.png'
import c7 from './graphics/cards/7.png'
import c8 from './graphics/cards/8.png'
import c9 from './graphics/cards/9.png'
import c10 from './graphics/cards/10.png'

import opensea from "./graphics/opensea.png"

import styled from "styled-components";

const Hide = styled.div`

@media screen and (max-width: 768px){
    display: none;
}
`
const STDIV = styled.div`
// margin-left: 300px;
@media screen and (max-width: 768px){
    // margin-left: 0px;
}
`
const Collection = () => {

    return (
        <FaqContainer id="collection">
        {/* <FaqWrapper> */}
       
         <div className="containter py-5 img-fluid"
        style={{  backgroundPosition: "top right", height: "auto", objectFit: "contain"}}>
          
           
            <div style={{textAlign: "center"}}>
     
                <Zoom left>
                <p className="fw-bold " style={{fontSize: "4rem", color:"#fff" , lineHeight: " "}}>
               Coll<span style={{color:"#DA0000"}}>ectio</span>n
           </p>
           </Zoom>
       
           </div>
       
         
    
        <div className="container-fluid mx-auto  "> 
            <div  className=" mt-5">
        <Marquee  gradient={false}>
           <img style={{height:"300px"}} src={c1}   alt="Image 1"/>
           <img style={{height:"300px"}} src={c2}   alt="Image 1"/>
           <img style={{height:"300px"}} src={c3}   alt="Image 1"/>
           <img style={{height:"300px"}} src={c4}   alt="Image 1"/>
           <img style={{height:"300px"}} src={c5}   alt="Image 1"/>
           <img style={{height:"300px"}} src={c6}   alt="Image 1"/>  
           <img style={{height:"300px"}} src={c7}   alt="Image 1"/>
           <img style={{height:"300px"}} src={c8}   alt="Image 1"/>
           <img style={{height:"300px"}} src={c9}   alt="Image 1"/>
           <img style={{height:"300px"}} src={c10}   alt="Image 1"/>
      
           </Marquee>
            <div className=" row  mx-auto justify-content-center">
                <div className=" col-xl-8 mx-auto col-lg-8 col-sm-12 ">
                    <div className="mx-auto text-center">
                        <p className="text-light  mt-5 fs-5">Discover the entire collection on</p>
                        <img className="img-fluid" style={{width: "10rem"}} src={opensea} alt=""/>
                    </div>
                </div>
            </div>
        </div>

  


        </div>
    </div>

        {/* </FaqWrapper> */}
        </FaqContainer>
    
    )
}

export default Collection
