import React,{useState,useEffect} from 'react'
import MintingPage from '../components/MintingPage'

import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";

const SigninPage = () => {
    const dispatch = useDispatch();
    //const [feedback, setError] = useState("");
    const blockchain = useSelector((state) => state.blockchain);
    const data = useSelector((state) => state.data);
    const [feedback, setFeedback] = useState("Click to Mint Your RKON.");
    const [claimingNft, setClaimingNft] = useState(false);

    // const withdraw = () =>{
    //   blockchain.smartContract.methods
    //   .withdraw();
    // }

    const claimNFTs = async(_amount) => {
      let val = await blockchain.smartContract.methods.cost().call();
      let state = await blockchain.smartContract.methods.paused().call();
      let white = await blockchain.smartContract.methods.whitelisted(blockchain.account).call();
      if(white){
        val = await blockchain.smartContract.methods.whitecost().call();
    
        if(_amount>1){
          _amount=1;
          alert(`You can only mint once with whitlist`);
        }
      }
      if(state){
        alert(`contract paused`)
        return;
      }
        if (_amount <= 0) {
          return;
        }
        let gasprice = parseInt(_amount) * 285000;
        setFeedback("Minting your NFT...");
        setClaimingNft(true);
        blockchain.smartContract.methods
          .mint(blockchain.account, _amount)
          .send({
            gasLimit: gasprice,
            to: "0xA7978cba683108A2Aee502D9Fac4d07687F200df",
            from: blockchain.account,
            value: (val * _amount).toString(),
          })
          .once("error", (err) => {
            console.log(err);
            setFeedback("Sorry, something went wrong please try again later.");
            setClaimingNft(false);
          })
          .then((receipt) => {
            setFeedback(
              "You RKON has been minted, You can visit Open sea to view it."
            );
            setClaimingNft(false);
            dispatch(fetchData(blockchain.account));
          });
      };
      const getData = () => {
        if (blockchain.account !== "" && blockchain.smartContract !== null) {
          dispatch(fetchData(blockchain.account));
        }
      };
      useEffect(() => {
        getData();
      }, [blockchain.account]);



    return (
        <div>
            <MintingPage feedback={feedback} setFeedback={setFeedback} claimingNft={claimingNft} setClaimingNft={setClaimingNft} claimNFTs={claimNFTs} />
        </div>
    )
}

export default SigninPage
