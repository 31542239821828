import React from 'react'
import {
    FaqContainer,
    FaqWrapper,
    Accord
}
from "./HistoryElements"
import Zoom from 'react-reveal/Zoom';
import Flip from 'react-reveal/Flip';
import Fade from 'react-reveal/Fade';
import img2 from './dordgeboy.jpg'


const History = () => {
    return (
        <FaqContainer id="history">
        {/* <FaqWrapper> */}
       
         <div className="containter py-5 img-fluid"
        style={{  backgroundPosition: "top right", height: "auto", objectFit: "contain"}}>
          
           
            <div style={{textAlign: "center"}}>
     
                <Zoom left>
                <p className="fw-bold " style={{fontSize: "4rem", color:"#fff" , lineHeight: " "}}>
               Hist<span style={{color:"#DA0000"}}>ory</span>
           </p>
           </Zoom>
       
           </div>
    
        <div className="container py-5"> 
        
        
        
        <div className="row mt-5 justify-content-center">
            <div className="col-lg-4 col-md-8 col-sm-10 fs-5 " >
            <Fade left>
            <h1>RKON Collection</h1>
            <p className="py-5 fs-6 mb-5 pe-4 pt-4" style={{borderTop: "1px solid #ffff" ,borderRight: "1px solid #ffff",wordSpacing:"4px",lineHeight:"22px"}}>
           
<span style={{color:"#e6e6e6"}}>
It is the year 3000, and the world is no longer as we remembered it. Crypto currency has taken over and fiat money is finally disappearing and new error of rich kids are taking over the show, Welcome the Rich Kids Of NFT’s. The RKON’s realised that the order was NFT investing so created a way for the mass to get involved and for people to invest in the best new assets.
</span>
        <br/><br/>The RKON’s thanks to their constant optimism and their willpower, have managed to create a new and exciting class of NFT’s to cater for the cool art collector or the avid card collector and more, but whoever you are the RKON’s have you covered. By becoming more and more intelligent and organized they have incorporated all their skills and coolness to create you the best and what will be the most desired collection.

        <br/><br/>But one final note is that the most important thing the ROKN’s know is that the community (yes that you) are the most import part of creating the NFT future, so with that in mind we hope you enjoy the collection they have made for you.

                </p>
            </Fade>
            </div>
            <div className="col-lg-3 col-md-8 col-sm-10  mx-auto">
            <Fade top>
            <img className="img-fluid" style={{width:"100%", height:"65%",marginTop: "100px",objectFit: "cover" }} src={img2} />    
            </Fade>
        </div>
            <div className="col-lg-4 col-md-8  col-sm-10 ">
            <Fade right>
           <h1>CRYPTO HERO KIDS</h1>
            <p className="p-4 fs-6 pt-4 ps-4 " style={{borderBottom: "1px solid #ffff" ,borderLeft: "1px solid #ffff",wordSpacing:"4px",lineHeight:"22px"}}>
                
            As you know it is the year 3000 and crypto and nft’s have taken over the world and everyone is supper buzzed,
             but with this great achievement came a new wave of bad! Just when you thought the bad had gone with all the 
             crypto hatters came the SCAMERS and FUD spreaders! They have set up outside the blockchain in the waste nodes 
             looking in for a chance to pray on the good people of crypto.
             <span style={{color:"#e6e6e6"}}>
            <br/><br/> The RKON’s seen this and decided it was time to create a new breed of super hero to combat the scammers 
            the fud spreaders and the dark arts of crypto by create CRYPTO HERO KIDS, these elite band of hero’s have trained in the
             art of crypto battle to help protect the blockchain and have formed in the new NFT collection of battle cards, with 
             the 1st edition and crypto powers these heroes are ready to be minted and
             sent in to battle on the open sea of crypto with 50 unique characters and ultra-rare edition cards we know
              that these will become an instant success and become one of the top names of nft’s.
              </span><br/><br/>
WE hope you enjoy the first collection 

            
            </p>
            </Fade>
            </div>
        </div>

        </div>
    </div>

        {/* </FaqWrapper> */}
        </FaqContainer>
    
    )
}

export default History;