import React from 'react'
import {
    FaqContainer,
    FaqWrapper,
    Accord
}
from "./TermsElements"
import logo from '../../images/logo.png'
import {Link as LinkS} from 'react-scroll';
import {Link as LinkR} from 'react-router-dom';
import Zoom from 'react-reveal/Zoom';
import Flip from 'react-reveal/Flip';
import Fade from 'react-reveal/Fade';
import bg1 from '../../images/red-tex.jpg'
import styled from "styled-components";

const Hide = styled.div`

@media screen and (max-width: 768px){
    display: none;
}
`
const Icon = styled(LinkR)`
        margin-left: 32px;
        margin-top: 32px;
        text-decoration: none ;
        color: #fff;
        font-weight: 700;
        font-size: 32px;

        @media screen and (max-width: 480px){
            margin-left: 16px;
            margin-top: 8px;

        }
`;

export const Text = styled.span`
        text-align: center;
        margin-top: 24px;
        
        color: #fff;
        font-size: 14px;
`
export const NavBtnLink = styled(LinkS)`
    border-radius: 50px;
    background: #DA0000;
    white-space: nowrap;
    padding: 10px 22px;
    color: #FFF;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none ;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #fff;
        color: #da0000 !important;
    }
`;
const STDIV = styled.div`
// margin-left: 300px;
@media screen and (max-width: 768px){
    // margin-left: 0px;
}
`
export const FormContent = styled.div`
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        style={{margin: "0px 150px"}}
        
        @media screen and (max-width: 480px){
            padding: 10px;
        }
        
`;
const Terms = () => {
    return (
        <FaqContainer >
        <Icon to="/">    <img style={{height:"150px", width:"200px"}} src={logo}/> </Icon>
       <div>
       <FormContent >
       <h1 style={{color:"#FFF" , textAlign:"center"}}>
                RKON TERMS 
                </h1>
                <h2 style={{color:"#FFF", textAlign:"center"}}>
                TERMS 
                </h2>
                <Text>
                RKON is a collection of digital artworks (NFTs) running on the Ethereum network. This website is only an interface allowing participants to exchange digital collectibles. Users are entirely responsible for the safety and management of their own private wallets and validating all transactions and contracts generated by this website before approval. Furthermore, as the RKON smart contract runs on the ETH network, there is no ability to undo, reverse, or restore any transactions.
                </Text>
                <Text>
                This website and its connected services are provided “as is” and “as available” without warranty of any kind. By using this website you are accepting sole responsibility for any an all transactions involving RKON digital collectibles.  </Text>
                
                <h2 style={{color:"#FFF", textAlign:"center"}}>
                OWNERSHIP 
                </h2>
                <Text>
                i. You Own the NFT. Each RKON is an NFT on the Ethereum blockchain. When you purchase an NFT, you own the underlying NFT, the Art, completely. Ownership of the NFT is mediated entirely by the Smart Contract and the Ethereum Network: at no point may we seize, freeze, or otherwise modify the ownership of any NFT.
                </Text>
                <Text>
                ii. Personal Use. Subject to your continued compliance with these Terms RKON grants you a worldwide, royalty-free license to use, copy, and display the purchased Art, along with any extensions that you choose to create or use, solely for the following purposes: (i) for your own personal, non-commercial use; (ii) as part of a marketplace that permits the purchase and sale of your RKON / NFT, provided that the marketplace cryptographically verifies each RKON NFT owner’s rights to display the Art for their NFT to ensure that only the actual owner can display the Art; or (iii) as part of a third party website or application that permits the inclusion, involvement, or participation of your NFT, provided that the website/application cryptographically verifies each RKON owner’s rights to display the Art for their NFT to ensure that only the actual owner can display the Art, and provided that the Art is no longer visible once the owner of the NFT leaves the website/application.
               </Text>
               <Text>
               iii. Commercial Use. Subject to your continued compliance with these Terms, Degen Academy LLC grants you an unlimited, worldwide license to use, copy, and display the purchased Art for the purpose of creating derivative works based upon the Art (“Commercial Use”). Examples of such Commercial Use would e.g. be the use of the Art to produce and sell merchandise products (T-Shirts etc.) displaying copies of the Art. For the sake of clarity, nothing in this Section will be deemed to restrict you from (i) owning or operating a marketplace that permits the use and sale of RKON NFTS generally, provided that the marketplace cryptographically verifies each NFT owner’s rights to display the Art for their NFT to ensure that only the actual owner can display the Art; (ii) owning or operating a third party website or application that permits the inclusion, involvement, or participation of NFT generally, provided that the third party website or application cryptographically verifies each RKON NFT owner’s rights to display the Art for their NFT to ensure that only the actual owner can display the Art, and provided that the Art is no longer visible once the owner of the Purchased Degen Ape leaves the website/application; or (iii) earning revenue from any of the foregoing.
                
                </Text>
                </FormContent>
        </div>

        </FaqContainer>
    
    )
}

export default Terms
